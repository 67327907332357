




































































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

@Component({
  filters: filters,
})
export default class ZoneCard extends Vue {
  @Prop() session!: any
  @Prop() event!: any
  @Prop() zone!: any
  @Prop() index!: number
  @Prop() hasFees!: boolean
  visible = false

  ticketsType() {
    if (!this.clickable) return

    this.$store.dispatch("gtm/step2", {
      event: this.event,
      session: this.session,
      zone: this.zone,
      event_lookup_ref: this.$route.params.event_lookup_ref,
    })

    this.$emit("zoneClick", this.zone.id)
  }

  openInfo() {
    this.visible = true
  }

  closeInfo() {
    this.visible = false
  }

  get available() {
    return this.zone.total_price !== null
  }
  get purchasable() {
    return this.zone.purchasable_seats_count > 0
  }
  get clickable() {
    return this.available && this.purchasable
  }
  get showTotalAvailableSeats() {
    return this.zone.active_seats_count != null
  }
  get hasLayout() {
    return this.zone.layout.d
  }
}
