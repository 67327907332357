<template>
  <div ref="drawing" class="place-map">
    <div v-show="showStage" class="stage" v-translate>Escenario</div>
  </div>
</template>
<script>
import _ from "lodash"

export default {
  name: "PlaceMap",
  data: function () {
    return {
      svgContainer: null,
      selectedNodeId: null,
      elements: [],
      initializedMap: false,
    }
  },
  props: ["data", "viewbox", "stageLayout"],
  mounted() {
    this.drawZones()
  },
  computed: {
    showStage() {
      const firstStage = _.first(this.stageLayout)
      if (
        !firstStage ||
        firstStage.attr === undefined ||
        firstStage.attr.display === undefined
      ) {
        // Default behavior is to show a stage
        return true
      } else {
        // Otherwise, obey the show property
        return firstStage.attr.display !== "none"
      }
    },
  },
  methods: {
    async generateVenueMap() {
      this.svgContainer = await this.$svg(this.$refs.drawing)
      this.initializedMap = true
    },
    async drawZones() {
      if (!this.initializedMap) {
        this.generateVenueMap().then(() => {
          this.drawZones()
        })
        return
      }

      this.data.forEach((zone) => {
        if (!zone.svg) return
        const isClickable = zone.ticket_price && zone.purchasable_seats_count > 0
        var zoneSVG = this.svgContainer
          .group()
          .attr({
            width: zone.size_x,
            height: zone.size_y,
            class: isClickable ? "clickable" : "disabled",
          })
          .translate(zone.offset_x, zone.offset_y)
          .rotate(zone.rotation_angle, zone.size_x / 2, zone.size_y / 2)
        zoneSVG.node.innerHTML = zone.svg
        zoneSVG.element("title").words(zone.name)

        let offset = 20
        zoneSVG
          .rect()
          .attr({ fill: zoneSVG.first().attr("fill"), style: "opacity: 0" })
          .move(-offset, -offset)

        if (isClickable) {
          zoneSVG.click(() => {
            this.$emit("map-clicked", zone.id)
          })
        }
      })

      this.svgContainer.viewbox(
        this.viewbox[0],
        this.viewbox[1],
        this.viewbox[2],
        this.viewbox[3]
      )
    },
  },
  watch: {
    data: {
      handler() {
        if (this.svgContainer) {
          this.svgContainer.remove()
          this.initializedMap = false
        }
        this.drawZones()
      },
      deep: true,
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_responsive.sass'
@import '@/assets/sass/_variables.sass'

.place-map
  text-align: center
  background: white
  border: 1px solid $grey-lighter
  padding: 5px
  margin: 0 .5rem 0 0
  text-align: center
  display: flex
  flex-direction: column
  @include until($tablet)
    border: none
    border-bottom: 1px solid $grey-lighter
    margin: 0 .5rem 0 .5rem
  svg
    max-width: $content-max-width * 2/3
    height: auto
  .clickable
    rect
      cursor: pointer
      stroke: white
  .disabled
    rect
      fill: #DFDFDF
      stroke: white
  .stage
    background: #000
    color: white
    margin-top: 1rem
    width: 60%
    align-self: center
    padding: .5rem 0rem
    -webkit-order: 1
</style>
