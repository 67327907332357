
































































import _ from "lodash"
import "reflect-metadata"
import { Component, Watch } from "vue-property-decorator"
import { mapState } from "vuex"
import Vue from "vue"
import EventDetail from "@/components/EventDetail.vue"
import CalendarChangeSession from "@/components/calendar/CalendarChangeSession.vue"
import filters from "@/filters"
import Cart from "@/components/Cart.vue"
import CartIcon from "@/components/CartIcon.vue"
import PlaceMap from "@/components/PlaceMap.vue"
import ZoneCard from "@/components/ZoneCard.vue"
import TokenTimer from "@/components/TokenTimer.vue"
import TokenExpiredDialog from "@/components/TokenExpiredDialog.vue"

@Component({
  filters: filters,
  components: {
    Cart,
    CartIcon,
    CalendarChangeSession,
    EventDetail,
    PlaceMap,
    ZoneCard,
    TokenTimer,
    TokenExpiredDialog,
  },
  computed: {
    ...mapState({
      cart: (state: any) => state.shoppingCart.cart,
    }),
  },
})
export default class Session extends Vue {
  eventSimple: Dictionary<String> | null = null
  session: {
    id: null
    lookup_ref: null
    start_date: string
    start_time: string
    stage_layout: Array<Dictionary<any>> | null
    viewbox: Array<number>
    session_zones: Array<Dictionary<any>>
    event: Array<Dictionary<any>> | {}
  } | null = null

  placeMapData: Array<Dictionary<any>> = []
  ticketsDropdownOpen: boolean = false
  tokenExpired = false

  get isLoading() {
    return !this.session
  }

  get sessionZones(): Array<Dictionary<any>> {
    if (!this.session) return []
    return this.session.session_zones
  }

  get hasMap() {
    return _.some(this.sessionZones, (zone) => _.size(zone.svg))
  }

  get stageLayout() {
    if (!this.session) return null
    return _.isArray(this.session.stage_layout) ? this.session.stage_layout : null
  }

  mounted() {
    this.getEventSimple()
    this.getSession()
  }

  getEventSimple() {
    this.$api.getEventSimple(this.$route.params.event_lookup_ref).then(
      (response: any) => {
        this.eventSimple = response
      },
      () => {}
    )
  }

  getSession() {
    this.session = null

    this.$api
      .getSession(this.$route.params.session_lookup_ref)
      .then((response: any) => {
        this.session = response
        this.buildMap()
        this.notifyGTM(this.session)
      })
  }

  step2Notified = false

  notifyGTM(session: any) {
    if (this.step2Notified == false) {
      this.$store.dispatch("gtm2404/beginCheckout", {
        session: session,
      })
      this.step2Notified = true
    }
  }

  buildMap() {
    if (!this.session) return

    this.placeMapData = []
    const zones = _.compact(
      _.map(this.session.session_zones, (session_zone: any) => {
        if (!session_zone.layout) return null
        return {
          name: session_zone.name,
          purchasable_seats_count: session_zone.purchasable_seats_count,
          ticket_price: session_zone.ticket_price,
          svg: session_zone.svg,
          size_x: session_zone.size_x,
          size_y: session_zone.size_y,
          offset_x: session_zone.offset_x,
          offset_y: session_zone.offset_y,
          rotation_angle: session_zone.rotation_angle,
          rotation_sizes: session_zone.rotation_sizes,
          id: session_zone.id,
        }
      })
    )

    this.placeMapData = zones
  }

  goToZone(zoneId: string) {
    this.$router.push({
      name: "zone",
      params: { zone_id: zoneId },
      query: this.$defaultQuery(),
    })
  }

  @Watch("$route.params.session_lookup_ref")
  onSessionChange() {
    if (
      this.session &&
      this.$route.params.session_lookup_ref != this.session.lookup_ref
    ) {
      this.getSession()
    }
  }

  get ffTokenTimerEnabled() {
    return this.$env && this.$env.ff_token_timer === true
  }
}
